/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useMemo } from 'react';
import { usePreFooterMiddleData } from '../../api/getPreFooterData';
import { DesktopLinkColumn } from './DesktopLinkColumn';
import { MobileLinkColumn } from './MobileLinkColumn';
import styles from './styles.module.scss';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Grid } from '@/components/Grid';
import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import { useFeatureFlag } from '@/features/globalConfig';

export function PreFooter() {
  const gcpUrlEnabled = useFeatureFlag('SEO_GCP_URL_ENABLED') === 'true';
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { data } = usePreFooterMiddleData({ gcpUrlEnabled });

  const filteredData = useMemo(() => {
    return (
      data
        ?.filter(
          (content) =>
            typeof content.contents !== 'undefined' &&
            content.contents.length > 0 &&
            content.contents.some((item) => (item?.content?.length ?? -1) > 0)
        )
        .slice(0, 4) ?? []
    );
  }, [data]);

  if (!filteredData.length) {
    return null;
  }

  return (
    <section className={styles.addMargin} data-testid="preFooter">
      <div className={styles.preFooterContainer}>
        <div className={styles.preFooterInnerContainer}>
          {filteredData.map((column, index) => {
            const contents = column.contents?.[0];
            if (!contents?.content || !contents.content.length) {
              return null;
            }
            return (
              <Grid item container lg={3} md={6} key={`linkColumContainer_${index}`}>
                <DeviceWrapper
                  desktop={contents.desktop}
                  tablet={contents.tablet}
                  mobile={contents.mobile}
                >
                  {isMobile ? (
                    <div className={styles.mobileLinkContainer}>
                      <MobileLinkColumn
                        key={`linkColumn-${index}`}
                        title={column.name}
                        links={contents.content}
                      />
                    </div>
                  ) : (
                    <DesktopLinkColumn
                      key={`linkColumn-${index}`}
                      title={column.name}
                      links={contents.content}
                    />
                  )}
                </DeviceWrapper>
              </Grid>
            );
          })}
        </div>
      </div>
    </section>
  );
}
